import { groupBy, formatNumber, formatPrice, stringToRGB } from '@/utils/utils'
import { ChartType, YAxisFormat } from '@/enums'
import moment from 'moment'
import { ApexOptions } from 'apexcharts'
import snakeCase from 'lodash/snakeCase'
import { IOpts } from '@/interfaces'

interface IData {
  [key: string]: any
  deliveryMonth: number
  unitPrice: number
  organisationName?: string
  materialTypeCode?: string
}

export interface IChartContext {
  data: IData[] | null
  type: ChartType
  groupByParam?: 'materialTypeCode' | 'regionName' | 'organisationName'
  labelsKey?: string
  seriesKey?: string
  format?: YAxisFormat
  isStacked?: boolean
  isCustomColors?: boolean
  csvHeaderCategoryTitle?: string
}
interface IChartSeriesItem {
  name: string
  data: number[]
}

export const yAxisFormatter = (
  format: YAxisFormat | undefined,
  value: number | null
): string | null => {
  if (value === null) return null

  if (format === YAxisFormat.Amount) {
    return `${formatNumber(value)} t`
  } else if (format === YAxisFormat.Currency) {
    return String(formatPrice(value, true))
  }
  return String(value)
}

export interface IUseChartContext {
  chartOptions: ApexOptions
  series: IChartSeriesItem[]
}

export default function useChartContext(
  {
    data,
    type,
    groupByParam,
    labelsKey,
    seriesKey,
    format,
    isStacked = false,
    isCustomColors = false,
    csvHeaderCategoryTitle = 'category',
  }: IChartContext,
  title: string | undefined
): IUseChartContext | null {
  if (!data) return null

  const series: any[] = []
  const labels: any[] = []
  let xAxisCategories: unknown[] = []

  if (
    [ChartType.Bar, ChartType.Area].includes(type) &&
    groupByParam &&
    seriesKey
  ) {
    const normalizedData = groupBy(data, groupByParam)

    xAxisCategories = Array.from(
      new Set(data.map((item: IData) => item.deliveryMonth))
    )

    Object.keys(normalizedData).forEach((name: string) => {
      const seriesItem: IChartSeriesItem = {
        name,
        data: Array(xAxisCategories.length).fill(null),
      }

      normalizedData[name].forEach((item: IData) => {
        const index = xAxisCategories.indexOf(item.deliveryMonth)
        seriesItem.data[index] = item[seriesKey]
      })

      series.push(seriesItem)
    })

    series.sort(<T extends IChartSeriesItem>(a: T, b: T) => {
      if (a.name === b.name) {
        return 0
      } else {
        return a.name < b.name ? -1 : 1
      }
    })
  } else if (labelsKey && seriesKey) {
    data.forEach((item: any) => {
      series.push(item[seriesKey])
      labels.push(item[labelsKey])
    })
  }

  const chartColors = () => {
    const cb = ({ seriesIndex }: IOpts) => {
      const materialType = series[seriesIndex]?.name

      return stringToRGB(materialType)
    }

    return [...new Array(series.length)].map(() => cb)
  }

  if ([ChartType.Bar, ChartType.Area].includes(type)) {
    return {
      series,
      chartOptions: {
        chart: {
          animations: {
            enabled: false,
          },
          type: xAxisCategories.length === 1 ? ChartType.Scatter : type,
          stacked: isStacked,
          toolbar: {
            show: true,
            tools: {
              selection: false,
              zoom: false,
              zoomin: false,
              zoomout: false,
              pan: false,
              reset: false,
            },
            export: {
              csv: {
                filename: `${snakeCase(title)}_export`,
                headerCategory: csvHeaderCategoryTitle,
              },
            },
          },
          zoom: {
            enabled: true,
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
          },
        },
        xaxis: {
          type: 'category',
          labels: {
            rotate: -45,
            rotateAlways: true,
            formatter: function (value: string | number): string {
              if (value < 10) return ''

              return moment(value).format('MM.YYYY')
            },
          },
          categories: xAxisCategories,
        },
        yaxis: {
          labels: {
            formatter: (value: number | null): string =>
              <string>yAxisFormatter(format, value),
          },
        },
        legend: {
          position: 'bottom',
        },
        fill: {
          opacity: 1,
        },
        tooltip: {
          enabled: true,
          x: {
            show: true,
            format: 'MMM yyyy',
            formatter: undefined,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: 'straight',
          width: 1,
        },
        ...(isCustomColors ? { colors: chartColors() } : {}),
      },
    }
  }

  return {
    series,
    chartOptions: {
      labels,
      tooltip: {
        shared: true,
        y: {
          formatter: (value: number | null): string =>
            <string>yAxisFormatter(format, value),
        },
      },
      legend: {
        position: labels.length > 10 ? 'right' : 'bottom',
        width: labels.length > 10 ? 200 : 0,
      },
      chart: {
        height: 350,
        type,
        animations: {
          enabled: false,
        },
      },
    },
  }
}
