import { render, staticRenderFns } from "./Dashboard.vue?vue&type=template&id=5a5d511a&scoped=true&"
import script from "./Dashboard.vue?vue&type=script&lang=ts&"
export * from "./Dashboard.vue?vue&type=script&lang=ts&"
import style0 from "./Dashboard.vue?vue&type=style&index=0&id=5a5d511a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5a5d511a",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VMain } from 'vuetify/lib/components/VMain';
installComponents(component, {VMain})
