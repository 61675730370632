















































































import {
  defineComponent,
  reactive,
  toRefs,
  computed,
  onMounted,
  SetupContext,
  watch,
  ref,
} from '@vue/composition-api'
import moment from 'moment'
import { TranslateResult } from 'vue-i18n'
import DateRangePicker from '@/components/common/DateRangePicker.vue'
import { Dropdown } from '@/components/molecules/Dropdown'
import { DropdownMulti } from '@/components/molecules/DropdownMulti'
import { IPartnerFilterItems } from '@/modules/dashboard/interfaces'
import { OfferContractType, OfferType } from '@/enums'

enum PickerOptionsUnit {
  Days = 'days',
  Month = 'month',
  Months = 'months',
  Year = 'year',
}
interface IOptions {
  key: string
  amount: number
  unit: PickerOptionsUnit
}
interface IDropdownItem {
  text: TranslateResult
  value: string | null
}

const pickerOptions = (context: SetupContext) => {
  const options: IOptions[] = [
    {
      key: 'lastWeekLabel',
      amount: 7,
      unit: PickerOptionsUnit.Days,
    },
    {
      key: 'lastMonthLabel',
      amount: 1,
      unit: PickerOptionsUnit.Month,
    },
    {
      key: 'last3MonthsLabel',
      amount: 3,
      unit: PickerOptionsUnit.Months,
    },
    {
      key: 'last6MonthsLabel',
      amount: 6,
      unit: PickerOptionsUnit.Months,
    },
    {
      key: 'lastYearLabel',
      amount: 1,
      unit: PickerOptionsUnit.Year,
    },
  ]

  return {
    shortcuts: options.map((option: IOptions) => {
      return {
        text: context.root.$t(`dashboard.filter.dateRange.${option.key}`),
        onClick(picker: any) {
          picker.$emit('pick', [
            moment().subtract(option.amount, option.unit),
            moment(),
          ])
        },
      }
    }),
  }
}

export default defineComponent({
  components: {
    DateRangePicker,
    Dropdown,
    DropdownMulti,
  },
  setup(_, context: SetupContext) {
    const router = context.root.$options.router
    const store = context.root.$store
    const i18n = context.root.$i18n
    const organisation = ref(null)

    const getDropdownItems = (
      rawItems: string[],
      i18nKey: string
    ): IDropdownItem[] => {
      const nullItem = {
        text: i18n?.t('dashboard.filter.noEntry'),
        value: null,
      }
      const items: IDropdownItem[] = [nullItem]
      rawItems.forEach((rawItem) => {
        items.push({
          text: i18n?.t(`dashboard.filter.${i18nKey}.${rawItem}`),
          value: rawItem,
        })
      })
      return items
    }

    const filters = reactive({
      filtersFromQuery: computed(
        () => store.getters['dashboard/getFiltersFromQuery']
      ),
      queryFromFilters: computed(
        () => store.getters['dashboard/getQueryFromFilters']
      ),
      partnerFilterItemsData: computed(
        () => store.getters['dashboard/getPartnerFilterItemsData']
      ),
      materialTypeCodeFilterItemsData: computed(() => {
        const data =
          store.getters['dashboard/getMaterialTypeCodeFilterItemsData']
        return data?.map((item: string) => ({ text: item, value: item }))
      }),
      isShippingIncludedItems: computed(() =>
        getDropdownItems(['1', '0'], 'isShippingIncluded')
      ),
      contractTypeItems: computed(() =>
        getDropdownItems([...Object.values(OfferContractType)], 'contractType')
      ),
      transactionTypeItems: computed(() =>
        getDropdownItems([...Object.values(OfferType)], 'transactionType')
      ),
    })

    const updateQuery = async () => {
      await router?.push({
        query: filters.queryFromFilters,
      })
      await store.dispatch(
        'dashboard/fetchFiltersFromQuery',
        router?.currentRoute.query
      )
    }

    const select = (key: string, value: string) => {
      if (value !== '') {
        Object.assign(filters.queryFromFilters, { [key]: value })
      }
      updateQuery()
    }

    const mapFilters = () => {
      const filter =
        filters.filtersFromQuery.organisationIds &&
        filters.partnerFilterItemsData
          ? filters.partnerFilterItemsData.filter(
              (item: IPartnerFilterItems) =>
                (item.value = filters.filtersFromQuery.organisationIds)
            )
          : null

      if (filter) {
        organisation.value = filter[0].text
      }
    }

    onMounted(async () => {
      await store.dispatch(
        'dashboard/fetchFiltersFromQuery',
        router?.currentRoute.query
      )
      await store.dispatch('dashboard/fetchAllCharts')
      mapFilters()
    })

    watch(
      () => context.root.$route,
      () => {
        context.root.$nextTick(
          async () => await store.dispatch('dashboard/fetchAllCharts')
        )
      },
      { deep: true }
    )

    return {
      updateQuery,
      select,
      organisation,
      ...toRefs(filters),
      pickerOptions: pickerOptions(context),
    }
  },
})
