
























































import {
  defineComponent,
  ref,
  reactive,
  toRefs,
  computed,
  SetupContext,
  watch,
} from '@vue/composition-api'
import { DashboardFilter } from '@/components/dashboard/DashboardFilter'
import { ChartType, YAxisFormat } from '@/enums'
import { Chart } from '@/components/dashboard/Chart'
import { IChartContext } from '@/components/dashboard/Chart/composable'
import { LoadingSpinner } from '@/components/atoms/LoadingSpinner'
import { PageTitle } from '@/components/atoms/PageTitle'

export default defineComponent({
  components: {
    PageTitle,
    DashboardFilter,
    Chart,
    LoadingSpinner,
  },
  setup(_, context: SetupContext) {
    const isPageLoading = ref(true)
    const event = reactive({
      filtersFromQuery: computed(
        () => context.root.$store.getters['dashboard/getFiltersFromQuery']
      ),
      getIsPageLoading: computed(
        () => context.root.$store.getters['dashboard/getIsLoading']
      ),
      amountPerMonthChartContext: computed(() => {
        return {
          type: ChartType.Bar,
          data:
            context.root.$store.getters['dashboard/getAmountPerMonthChartData'],
          groupByParam: 'materialTypeCode',
          seriesKey: 'totalAmount',
          filters: context.root.$store.getters['dashboard/getFiltersFromQuery'],
          format: YAxisFormat.Amount,
          isStacked: true,
          isCustomColors: true,
          csvHeaderCategoryTitle: context.root.$t(
            'dashboard.chart.csvHeaderCategoryTitle'
          ),
        } as IChartContext
      }),
      amountPerOrganisationChartContext: computed(() => {
        return {
          type: ChartType.Donut,
          data:
            context.root.$store.getters[
              'dashboard/getAmountPerOrganisationChartData'
            ],
          labelsKey: 'organisationName',
          seriesKey: 'totalAmount',
          format: YAxisFormat.Amount,
        } as IChartContext
      }),
      contractTypeChartContext: computed(() => {
        return {
          type: ChartType.Pie,
          data:
            context.root.$store.getters['dashboard/getContractTypeChartData'],
          labelsKey: 'contractType',
          seriesKey: 'totalAmount',
          format: YAxisFormat.Amount,
        } as IChartContext
      }),
      pricePerMaterialTypeChartContext: computed(() => {
        return {
          type: ChartType.Area,
          data:
            context.root.$store.getters[
              'dashboard/getPricePerMaterialTypeChartData'
            ],
          groupByParam: 'materialTypeCode',
          seriesKey: 'unitPrice',
          isCustomColors: true,
          format: YAxisFormat.Currency,
          csvHeaderCategoryTitle: context.root.$t(
            'dashboard.chart.csvHeaderCategoryTitle'
          ),
        } as IChartContext
      }),
      pricePerRegionChartContext: computed(() => {
        return {
          type: ChartType.Area,
          data:
            context.root.$store.getters['dashboard/getPricePerRegionChartData'],
          groupByParam: 'regionName',
          seriesKey: 'unitPrice',
          format: YAxisFormat.Currency,
        } as IChartContext
      }),
      pricePerPartnerChartContext: computed(() => {
        return {
          type: ChartType.Area,
          data:
            context.root.$store.getters[
              'dashboard/getPricePerPartnerChartData'
            ],
          groupByParam: 'organisationName',
          seriesKey: 'unitPrice',
          format: YAxisFormat.Currency,
        } as IChartContext
      }),
    })

    watch(
      () => event.getIsPageLoading,
      (newValue) => {
        if (isPageLoading.value) {
          isPageLoading.value = newValue
        }
      },
      { deep: true }
    )

    return {
      isPageLoading,
      ...toRefs(event),
    }
  },
})
