




















import {
  ref,
  defineComponent,
  onMounted,
  watch,
  PropType,
} from '@vue/composition-api'
import useChartContext, {
  IChartContext,
  IUseChartContext,
} from '@/components/dashboard/Chart/composable'

export default defineComponent({
  props: {
    context: {
      type: Object as PropType<IChartContext>,
      default: () => null,
    },
    title: {
      type: String as PropType<string>,
      required: false,
      default: () => '',
    },
    isSelectBlockVisible: {
      type: Boolean as PropType<boolean>,
      default: () => false,
    },
  },
  setup(props) {
    const chart = ref<IUseChartContext | null>(null)

    onMounted(() => {
      chart.value = useChartContext(props.context, props.title)
    })

    watch(
      () => props.context,
      () => {
        chart.value = useChartContext(props.context, props.title)
      },
      { deep: true }
    )

    return {
      chart,
    }
  },
})
